import React from "react";
import Artykul from "../components/artykul";
import {  graphql } from "gatsby";
import Img from "gatsby-image";
import obrazekArtykulu from "../images/crm-dla-agencji-marketingowej-tlo.jpg";
import Lightbox from "../components/lightBox";
import { FaCalendarCheck, FaComments, FaCoins, FaUsers, FaChartLine, FaMedal } from "react-icons/fa";
import Container from "../components/container";
import BenefitBlock from "../components/benefitBlock";
import BenefitBlockWrapper from "../components/BenefitBlockWrapper";
import Columns from "../components/columns";

export const query = graphql`
  query {
    zdjecie1: file(relativePath: { eq: "prognozaszansy.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie2: file(relativePath: { eq: "kanban.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
     zdjecie3: file(relativePath: { eq: "kalendarzzlecen.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
const Crmagencjamarketingowa = ({ data }) => {
  return (
    <Artykul
      title="CRM dla agencji marketingowej"
      articleImage={obrazekArtykulu}
      keywords={["crm dla agencji marketingowej"]}
      articleImageAlt="dobry system crm dla agencji marketingowej."
      metaTitle="System CRM dla agencji marketingowej"
      metaDescription="CRM dostosowany do branży marketingowej ✅ Wsparcie sprzedaży • Zarządzanie projektami • Sprawna komunikacja • Wypróbuj bezpłatne demo"
    >
      <br />
      <p>Na rynku co roku pojawia się coraz więcej agencji marketingowych oferujących rozmaite usługi z zakresu promocji i 
      reklamy w Internecie. Klienci mają z czego wybierać, a firmy marketingowe muszą mierzyć się z&nbsp;rosnącą konkurencją. 
      Zapanowanie nad skutecznym pozyskiwaniem klientów, a następnie dostarczeniem im wysokiej jakości usług, jest już wyzwaniem, 
      z którym nie każde przedsiębiorstwo sobie poradzi. <strong>Jednym ze sposobów na wyprzedzenie konkurencji jest CRM dla agencji 
      marketingowej, który usprawni procesy biznesowe Twojej firmy.</strong>
</p>
<br/>
      <h2 style= {{
          textAlign: "left",
          marginBottom:20,
      }}>
        Jakie procesy usprawnia CRM w agencji marketingowej?
      </h2>
      <p>
      Twoja firma dostarcza <strong>usługi marketingowe z zakresu SEO lub SEM</strong>? Empireo to narzędzie, które świetnie się sprawdzi 
      zarówno w małych jak i średnich firmach. Bez względu na to, czy Twój zespół liczy kilka, kilkanaście czy kilkadziesiąt osób - 
      uporządkowane procesy to podstawa do rozwoju firmy i wzrostu przychodów.
CRM dla agencji marketingowej uporządkuje obszary:</p>
<p><ul>
<li>sprzedaży usług marketingowych,</li>
<li>realizacji zleceń marketingowych.</li>
 </ul>
      </p>




         <h2 style= {{
          textAlign: "left",
          marginBottom:20,
      }}>
        Jakie korzyści daje CRM dla agencji marketingowej?
      </h2>
        <BenefitBlockWrapper>
          <BenefitBlock
            icon={<FaChartLine size="45px" />}
            title="Wzrost przychodów firmy"
          >

          </BenefitBlock>
          <BenefitBlock
          icon={<FaMedal size="45px" />}
            title="Poprawa jakości obsługi klienta "
            
          >
          
          </BenefitBlock>
          <BenefitBlock icon={<FaUsers size="45px" />} title="Zwiększenie liczby klientów">
          
          </BenefitBlock>


        </BenefitBlockWrapper>
         <BenefitBlockWrapper>
          <BenefitBlock
            icon={<FaCalendarCheck size="45px" />}
            title="Terminowe wykonywanie zleceń "
          >

          </BenefitBlock>
          <BenefitBlock
            icon={<FaCoins size="45px" />}
            title="Obniżenie kosztów obsługi zleceń"
          >

          </BenefitBlock>
          <BenefitBlock icon={<FaComments size="45px" />} title="Usprawnienie komunikacji">
          
          </BenefitBlock>


        </BenefitBlockWrapper>


<h2 style= {{
          textAlign: "left",
          marginBottom:20,
      }}>
       Zwiększ sprzedaż swojej agencji marketingowej dzięki systemowi CRM
      </h2>
      <p>
       Empireo dostarcza szereg narzędzi usprawniających pracę handlowców. Do najważniejszych z nich należą:</p>
       <p><ul>
<li><strong>Baza kontrahentów</strong> - czyli zbiór informacji o potencjalnych, obecnych i byłych klientach.</li>
<li><strong>Kampanie szans sprzedaży</strong> - widoki Kanban przedstawiające lejek sprzedaży - na jakich etapach rozmów znajdują się leady 
sprzedażowe handlowców.</li>
<li><strong>Kalendarz handlowca</strong> - narzędzie ułatwiające koordynację pracy działu handlowego.</li>
<li><strong>Zadania i zdarzenia</strong> - planowane w kalendarzu zadania z podziałem na typy (np. spotkanie z klientem, kontakt telefoniczny, 
  przygotowanie oferty) oraz rejestrowane zdarzenia (po wykonaniu zadania) z notatkami pracowników.</li>
<li><strong>Raporty</strong> - np. konwersja lejka sprzedażowego, przyczyny utraty szans sprzedaży, aktywność handlowców.</li>
</ul></p>
<Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.zdjecie1]}
        alts={[
          "Zarządzanie szansami sprzedaży w systemie CRM dla agencji marketingowej",
        ]}
      />  
      <br/>
<h2 style= {{
          textAlign: "left",
          marginBottom:20,
      }}>
        Sprawnie zarządzaj projektami dla klientów
      </h2>
      <p>
       Gdy handlowiec pozyska klienta, dział realizacji musi dopilnować, by współpraca przebiegała wzorowo, 
       a&nbsp;projekt został terminowo wykonany, zgodnie z umową. CRM dla agencji marketingowej dostarcza rozwiązań wspierających 
       dział realizacji:</p>
<p><ul>
<li><strong>Widoki Kanban / Tablice zleceń</strong> - podgląd na przepływ realizowanych dla klientów zleceń.</li>
<li><strong>Własne typy zleceń</strong> - możesz utworzyć rodzaje projektów zgodnie z tym, co Twoja firma oferuje (np. pozycjonowanie 
  strony internetowej, copywriting, strategia marketingowa).</li>
<li><strong>Własne atrybuty zleceń</strong> - czyli dowolne pola wewnątrz karty zlecenia, które pracownicy mogą uzupełniać (komplet wszystkich 
  ustaleń z klientem).</li>
<li><strong>Kalendarz zleceń</strong> - podgląd na projekty przypisane do pracowników z perspektywy widoku Gantt.</li>
<li><strong>Rejestracja czasu</strong> - odnotowywanie poświęconego czasu przez pracowników na poszczególne zlecenia.</li>
<li><strong>Raporty</strong> - np. liczba zleceń w miesiącu z podziałem na typy, czas poświęcony przez pracowników na zlecenia klientów.</li>

      </ul></p>
      <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.zdjecie2]}
        alts={[
          "Zarządzanie projektami w systemie CRM dla agencji marketingowej",
        ]}
      />
<br/>
      <h2 style= {{
          textAlign: "left",
          marginBottom:20,
      }}>
        System CRM dopasowany do agencji marketingowej
      </h2>
      <p>
       Empireo to narzędzie skierowane typowo do firm działających w branży reklamy i promocji w Internecie. 
       Ten system CRM dla agencji marketingowej posiada wszystkie funkcje, niezbędne z perspektywy specyfiki tych właśnie usług. 
       <strong>Masz zatem pewność, że decydujesz się na rozwiązanie, które odpowiada potrzebom Twojego przedsiębiorstwa</strong> 
       i rozumie trudności 
       agencji oferujących działania SEO/SEM. 
      </p>
        <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.zdjecie3]}
        alts={[
          "Wykres Gantta w systemie CRM dla agencji marketingowej",
        ]}
      /> 
      <br/>
      <h2 style= {{
          textAlign: "left",
          marginBottom:20,
      }}>
        Skuteczne wdrożenie systemu CRM w agencji marketingowej
      </h2>
      <p>
       Empireo to prosty, gotowy system, który wdrożysz już w kilka dni, bez skomplikowanego procesu czy prac programistycznych. 
       Ponieważ jest on skierowany wyłącznie do firm działających w branży marketingowej, posiada szereg funkcjonalności dostosowanych 
       do jej specyfiki. <strong>Duża elastyczność, a równocześnie intuicyjność systemu CRM dla agencji marketingowej</strong>, pozwoli Ci na samodzielne 
       dostosowanie go w taki sposób, by odzwierciedlał procesy zachodzące w Twojej firmie. Decydujesz m.in. o:</p>
<p><ul>
<li>etapach kampanii szans sprzedaży,</li>
<li>typach zadań i zdarzeń,</li>
<li>typach zleceń marketingowych,</li>
<li>etapach zleceń i atrybutach wewnątrz nich,</li>
<li>wyglądzie pulpitu pracownika,</li>
<li>uprawnieniach pracowników do podglądu i edycji poszczególnych sekcji systemu.</li>
</ul>
      </p>
      <p>Widzisz obszary, które system CRM mógłby usprawnić w Twojej agencji marketingowej? Przekonaj się jak działa Empireo! </p>
<p><strong><a href="/demo/">Kliknij tutaj, aby wypróbować bezpłatnie Empireo.</a></strong></p> 
    </Artykul>
  );
};

export default Crmagencjamarketingowa;
